/*================================================================================
THEME COMPONENT
================================================================================*/
@import '@core/scss/base/pages/page-auth.scss';
@import '@core/scss/base/pages/app-email';
@import '@core/scss/base/pages/page-faq.scss';
@import '@core/scss/base/pages/app-calendar.scss';

/*================================================================================
LIBS COMPONENT
================================================================================*/
@import '@core/scss/base/components/_include';
@import '@core/scss/angular/libs/select.component';
@import '@core/scss/angular/libs/date-time-picker.component.scss';
@import '@core/scss/angular/libs/file-uploader.component.scss';
@import '~@fullcalendar/daygrid/main.css';
@import '@core/scss/angular/libs/sweet-alerts.component.scss';
@import '@core/scss/angular/libs/ng-zorror.component.scss';

/*================================================================================
CUSTOM COMPONENT
================================================================================*/

/*================================================================================
CUSTOM & OVERRIDE STYLE
================================================================================*/
@import './components/app.scss';
@import "@core/scss/angular/libs/datatables.component.scss";

.animate__animated {
  --animate-duration: 0.4s;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 20%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeOutDown {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(0, 20%, 0);
  }
}