@import '@core/scss/base/bootstrap-extended/include'; // Components includes

.ngx-file-drop__drop-zone {
  border: dashed 1px $custom-control-border-color !important;
  background-color: $gray-50 !important;
  border-radius: $border-radius !important;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: border-color 0.15s ease, background-color 0.15s ease-in-out;

  .ngx-file-drop__content {
    color: $body-color !important;
    transition: color 0.15s ease-in-out;
    height: 100% !important;
    width: 100%;
    position: relative;
  }
}

.drop-zone-preview {
  position: relative;
  min-height: 150px;

  .ngx-file-drop__drop-zone {
    min-height: 150px;
  }

  .preview {
    position: absolute;
    width: calc(100%);
    height: calc(100%);
    top: 0px;
    left: 0px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: $border-radius !important;
    background-color: $body-bg;

    .image-contain {
      position: absolute;
      width: 100%;
      height: 100%;
      padding: 1rem;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        max-width: 100%;
        max-height: 100%;
      }
    }
  }

  &.h-100 {
    min-height: 100px;

    .ngx-file-drop__drop-zone {
      min-height: 100px !important;
    }
  }
}

.file-drop-over {
  .ngx-file-drop__drop-zone {
    border-color: $primary !important;
    background-color: rgba($primary, 0.05) !important;

    .ngx-file-drop__content {
      color: $primary !important;
    }
  }
}

.only-button {
  .ngx-file-drop__drop-zone {
    height: auto !important;
    min-height: unset;
    background-color: unset !important;
    border: none !important;
  }
}

.close {
  padding: 0.1rem 0.62rem !important;
  box-shadow: 0 5px 20px 0 rgba($red, 0.25);
  border-radius: $border-radius;
  background-color: $white !important;
  opacity: 1;
  transition: all 0.23s ease 0.1s;
  position: relative;
  transform: translate(8px, -2px);

  .feather {
    color: $body-color;
    transition: all 0.15s ease;
  }

  &:hover,
  &:focus,
  &:active {
    opacity: 1 !important;
    outline: none;
    transform: translate(5px, 0px);
    background-color: $body-bg !important;
    box-shadow: none;

    .feather {
      color: $red;
    }
  }
}

.drop-mode {
  .ngx-file-drop__drop-zone {
    height: fit-content !important;
    padding: 0.75rem 0;
  }

  .ngx-file-drop__content {
    display: flex !important;
    flex-direction: column !important;
    align-items: center;
    justify-content: center;
  }
}

.inline-mode {
  .ngx-file-drop__drop-zone {
    border: none !important;
    height: auto !important;
  }

  .ngx-file-drop__content {
    padding: 0;
    text-align: left;
    display: flex !important;
    align-items: stretch !important;

    button {
      border-top-left-radius: $border-radius-sm;
      border-bottom-left-radius: $border-radius-sm;
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;

      &:disabled {
        background-color: $gray-100 !important;
        color: transparent !important;
      }
    }

    .file-display {
      position: relative;
      background-color: $white;
      border: 1px solid $border-color;
      border-left: 0px;
      border-top-right-radius: $border-radius-sm;
      border-bottom-right-radius: $border-radius-sm;
      display: flex;
      flex: 1;
      align-items: center;
      padding: 0rem 2rem 0rem 1rem;
      min-width: 0;
      transition: all 0.2s ease;

      p {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: $font-size-sm;
        line-height: 1.715rem;
        color: $brand-sub-3;
      }

      .file-remove {
        position: absolute;
        right: 15px;
        color: $danger;
      }

      &.disabled {
        opacity: 0.65;
        background-color: $gray-100 !important;
        color: transparent !important;
      }
    }
  }

  &.file-drop-over {
    .ngx-file-drop__content {

      button,
      .file-display {
        border-color: $primary !important;
      }
    }
  }
}

.file-display {
  // padding: 6px 15px !important;
  // line-height: 1.6;
  // border-radius: 3px !important;
  text-overflow: ellipsis;
  overflow: hidden;
}