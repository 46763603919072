// animate scss
@import '~animate.css/animate.min.css';

// extended sweet-alerts scss
@import '@core/scss/base/plugins/extensions/ext-component-sweet-alerts.scss';

.swal2-container {
  &.swal2-backdrop-show {
    background: rgba($black, 0.15) !important;
  }

  .swal2-header,
  .swal2-content {
    padding: 0 1.25rem;
  }

  .swal2-popup {
    box-shadow: $box-shadow;
    padding: 2rem 1.5rem;
    width: 25em;
    border-radius: 1rem;
  }

  .swal2-icon {
    font-family: $font-family-en;
    border: none;

    &.swal2-info {
      background-color: rgba($primary-sub, 0.15);
      color: $primary-sub;
    }

    &.swal2-warning {
      background-color: rgba($warning, 0.15);
      color: $warning;
    }

    &.swal2-success {
      border-color: $green;
      color: $green;

      [class^=swal2-success-line] {
        background-color: $green;
      }

      .swal2-success-ring {
        background-color: rgba($green, 0.10);
        border-color: transparent;
      }
    }

    &.swal2-error {
      .swal2-x-mark {
        background-color: rgba($red, 0.10);
        border-radius: 50%;

        [class^=swal2-x-mark-line] {
          background-color: $red;
        }
      }
    }
  }

  .swal2-actions {
    button {
      transition: all 0.2s ease;
      padding: 0.536rem 1.286rem;
      font-weight: 600;
      border: 1px solid transparent;
      border-radius: $btn-border-radius;
      min-width: 120px;
      background-color: $white;

      &:hover {
        background-image: none !important;
      }

      &.swal2-cancel {
        border-color: $custom-control-border-color;
        color: $body-color;

        &:hover {
          background-color: $gray-50;
        }
      }
    }
  }
}