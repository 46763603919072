// BASE
// .content-body {
//   padding: 0 1rem;

//   @include media-breakpoint-up(xxl) {
//     padding: 0 1.8rem;
//   }
// }

.flex-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  flex: 1;
}

// GRID
.row {
  margin-left: -0.75rem;
  margin-right: -0.75rem;
}

div[class^="col"],
div[class^="col-"] {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

a {
  transition: all 0.2s ease;

  &.primary {
    color: $primary;

    &:hover,
    &:active {
      color: darken($primary, 5%);
    }
  }

  &.link {
    display: block;
    font-size: $font-size-sm;
    color: $primary-sub-3;
    width: fit-content;

    &:hover,
    &:active {
      color: $primary-sub-2;
      text-decoration: underline;
    }
  }

  &.file-link {
    text-decoration: underline;

    &:hover,
    &:active {
      text-decoration: underline;
    }
  }
}

// MISC, UTIL
.sticky-top {
  position: sticky;
  top: 0;
}

.mh-xs {
  min-height: 175px !important;
}

.mh-sm {
  min-height: 250px !important;
}

.mh-md {
  min-height: 500px !important;
}

.mh-fit {
  min-height: calc(100vh - 140px) !important;
}

.flex-1 {
  flex: 1;
}

.version-text {
  margin: 0;
  font-family: $font-family-en;
  font-size: $font-size-sm;
  color: $text-muted;
}

*:focus-visible {
  outline: none !important;
}

.auth-login-form {
  .form-control {
    font-family: unset !important;
  }
}

.bg-gray {
  background-color: $gray-50;
}

.bg-brand-sub-2 {
  background-color: $primary-sub-2;
  color: $white;
}

.bg-light-brand-sub-2 {
  background-color: rgba($primary-sub-2, 0.12);
  color: $primary-sub-2;
}

.bg-light-purple {
  background-color: rgba($purple, 0.12);
  color: $purple;
}

.font-en {
  font-family: $font-family-en !important;
}

.font-th {
  font-family: $font-family-th !important;
}

.font-th-data {
  font-family: $font-family-th-data !important;
}

.w-sm-auto {
  @include media-breakpoint-up(sm) {
    width: auto !important;
  }
}

span.error {
  margin-top: 2px;
  color: $red;
  font-size: $font-size-sm;
}

.border-radius-none {
  border-radius: none !important;
}

// APP
.card-content-wrapper {
  padding: 1rem;
  border-radius: $border-radius;
  border: 1px solid $border-color;
  flex: 1;
  height: 100%;
}

.form-card-body {
  display: flex;
  flex-direction: column;
  min-height: calc(100% - 5.5rem);

  .tab-content {
    display: flex;
    width: 100%;

    .tab-pane {
      width: 100%;
    }
  }

  &-border {
    border: 1px solid $border-color;
    border-radius: $border-radius;
  }
}

.btn-icon-custom {
  padding: 0 !important;
}

.form-data {
  flex: 1;
}

.section-title {
  display: flex;
  align-items: center;
  margin-bottom: 0.75rem;

  .feather {
    stroke-width: 3;
  }

  &.box-style {
    padding: 1rem 1.5rem;
    margin-left: -1rem;
    margin-right: -1rem;
  }
}

.form-section {
  margin-bottom: 2.25rem;

  .form-sub-title {
    margin-top: 1rem;

    h4 {
      font-size: 1.143rem !important;
    }
  }
}

.file-container {
  background-color: $gray-50;
  padding: 1rem;
  border-radius: $border-radius-sm;
}

.section-container {
  background-color: $white;
  padding: 1rem;
  border-radius: $border-radius-sm;
  border: 1px solid $border-color;
}

.file-list-wrapper {
  .file-item {
    display: flex;
    align-items: center;
    background-color: $white;
    padding: 0.75rem;
    border-radius: $border-radius-sm;
    border: 1px solid $border-color;

    &:not(:last-child) {
      margin-bottom: 0.75rem;
    }

    &.active {
      border-color: $success;
      background-color: mix($white, $success, 90%);
    }

    &.active-border {
      border-color: $success;
    }

    &.error {
      border-color: $brand-sub-2;
      background-color: mix($white, $brand-sub-2, 95%);
    }

    &.warning {
      border-color: $warning !important;
      background-color: mix($white, $warning, 98%);
    }

    &.danger {
      border-color: $danger;
    }

    &.has_file {
      border-color: $primary-sub;
    }

    &.bordered {
      border-color: $gray-500;
    }

    .file-body {
      flex: 1;

      .file-action {
        display: flex;
        align-items: center;
      }
    }

    .file-right {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .file-checkbox-margin {
      margin-left: 2px;
      padding-left: 1.5rem;
    }

    .file-body+.file-right {
      border-left: 1px solid $border-color;
    }
  }

  .file-date {
    font-size: $font-size-sm;
    color: $text-muted;
  }

  .file-row {
    margin-left: -0.25rem;
    margin-right: -0.25rem;

    div[class^="col"],
    div[class^="col-"] {
      padding-left: 0.25rem;
      padding-right: 0.25rem;
    }
  }

  .file-box-stretch {
    .file-item {
      height: 100%;
      align-items: flex-start;
    }
  }
}

.file-style-box {
  text-align: left;
  display: block;
  padding: 0.5rem 1rem 0.5rem 0.5rem;
  background-color: mix($white, $primary-sub-3, 95%);
  border-radius: $border-radius;
  border: 1px solid mix($white, $primary-sub-3, 50%);
  font-size: $font-size-sm;
  color: $primary-sub-3;
  line-height: 1.2;

  &:hover {
    background-color: mix($white, $primary-sub-3, 90%);
    text-decoration: underline;
    color: $primary-sub-2;
  }

  .remove-file {
    color: $danger;
    position: absolute;
    right: 8px;
    top: 8px;
  }
}

.btn-file-detail {
  line-height: 1.2 !important;

  &:hover {
    color: $brand-sub-3;
  }
}

.remark-box {
  padding: 1rem;
  border-radius: $border-radius-sm;
  background-color: $gray-50;
  margin-top: 1.5rem;
}

.action-by {
  margin-top: 1rem;
  text-align: right;
}

.info-list {
  margin-bottom: 1rem;

  label {
    color: $text-muted;
    margin-bottom: 0;
  }

  .data {
    font-family: $font-family-th-data;
    margin-bottom: 0;
    font-weight: $font-weight-normal;
  }
}

.stats-card {
  box-shadow: none;
  // border: 1px solid $border-color;
  height: 100%;

  &.stats-success {
    border-color: $success;
    background-color: rgba($success, 0.05);
  }

  &.stats-danger {
    border-color: $danger;
    background-color: rgba($danger, 0.05);
  }

  .card-header {
    height: 100%;
  }
}

.card-badge {
  height: 42px;
  font-size: 1.286rem;
  padding: 0.5rem 1rem;
  border-radius: 99px;
  height: auto;
}

.placeholder-img {
  opacity: 0.75;

  img {
    width: 150px;
    height: auto;
  }
}

.tracking-timeline {
  .timeline-item {
    .timeline-point {
      &.timeline-point-indicator {
        left: -1.071rem;
        height: 28px;
        width: 28px;

        &::before {
          height: calc(2rem + 8px);
          width: calc(2rem + 8px);
        }

        &.timeline-point-success {
          .feather {
            stroke: $white !important;
            stroke-width: 3;
          }
        }
      }
    }
  }
}

.profile-box {
  border-radius: $border-radius;
  background-color: $gray-50;
  padding: 1rem;
}

.card-developer-meetup {
  padding: 1rem;
  border-radius: $border-radius-sm;
  border: 1px solid $border-color;

  .media-body {
    p {
      line-height: $line-height-md;
    }
  }
}

.list-search {
  width: 100%;
  flex-wrap: wrap;

  .form-control {
    width: 100%;
  }

  .datepicker-group {
    width: 100%;

    @include media-breakpoint-up(md) {
      width: 200px;
    }
  }

  .form-group-inline {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    label {
      white-space: nowrap;
    }
  }

  &.fixed-inline {
    @include media-breakpoint-up(sm) {
      .form-group-inline {
        flex-direction: row;
        align-items: center;
      }
    }
  }

  .ng-select-container {
    width: 100% !important;
  }

  .button-group {
    margin-left: auto;
    align-items: flex-end;
    margin-right: 0 !important;

    button {
      height: fit-content;
    }
  }

  &>*:not(.divider-line)+*:not(.divider-line) {
    @include media-breakpoint-up(xl) {
      margin-left: 1.25rem;
    }
  }

  @include media-breakpoint-up(md) {

    &>*:not(.divider-line)+*:not(.divider-line),
    &>.set-w {
      width: calc(50% - 0.75rem);
      margin-right: 1.5rem;

      &:nth-child(5n) {
        margin-right: 0px;
      }
    }
  }

  @include media-breakpoint-up(lg) {

    &>*:not(.divider-line)+*:not(.divider-line),
    &>.set-w {
      width: calc((100% / 3) - 1rem);

      &:nth-child(2n) {
        margin-right: 1.5rem;
      }

      &:nth-child(3n) {
        margin-right: 0px;
      }
    }

    &>.set-w {
      .datepicker-group {
        width: 100% !important;
      }
    }
  }

  @include media-breakpoint-up(xl) {
    &>.set-w {
      width: auto !important;
      margin-right: 0px !important;

      .datepicker-group {
        width: 200px !important;
      }
    }

    &>*:not(.divider-line)+*:not(.divider-line) {
      width: auto;
      margin-right: 0rem !important;
    }

    .form-group {
      width: 100%;
      margin-bottom: 0px;

      .form-control {
        width: 200px;
      }
    }

    .ng-select-container {
      width: 180px !important;
    }

    .form-group-inline {
      flex-direction: row;
      align-items: center;
    }
  }

  @include media-breakpoint-up(xxl) {
    .form-control {
      width: 250px !important;
    }

    .ng-select-container {
      width: 250px !important;
    }
  }

  .divider-line {
    border-right: 1px solid $border-color;
    margin: 0 1rem !important;

    &.h-line {
      border-bottom: 1px solid $border-color;
      margin: 1rem 0 !important;
    }
  }

  .full-w {
    .ng-select-container {
      width: 100% !important;
    }
  }
}

.report-search-date {
  @include media-breakpoint-up(md) {
    &>* {
      width: calc(50% - 0.75rem) !important;
      margin-right: 1.5rem !important;

      &:last-child,
      &:nth-child(2n+1) {
        margin-right: 0px !important;
      }
    }

    .daterange-wrapper,
    .datepicker-group {
      width: 100% !important;
    }
  }

  @include media-breakpoint-up(xl) {
    &>* {
      width: auto !important;
      margin-right: 0rem !important;
    }

    .daterange-wrapper,
    .datepicker-group {
      width: 320px !important;
    }
  }
}

.report-search-filter {
  flex-wrap: wrap;

  @include media-breakpoint-up(md) {
    &>*:not(.divider-line)+*:not(.divider-line) {
      margin-right: 1.5rem;

      &.main-filter,
      &:nth-child(2n+1) {
        margin-right: 0px;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    &>*:not(.divider-line)+*:not(.divider-line) {
      margin-right: 1.5rem !important;

      &.main-filter,
      &:nth-child(4n) {
        margin-right: 0px !important;
      }
    }
  }

  @include media-breakpoint-up(xl) {
    flex-wrap: nowrap;

    &>*:not(.divider-line)+*:not(.divider-line) {
      margin-right: 0px !important;
    }
  }
}

.accordion-custom {
  .card {
    // background-color: $gray-50;
    background-color: $white;
    border: 1px solid $gray-300 !important;
    box-shadow: none;
    border-radius: $card-border-radius !important;

    .card-body {
      background-color: $white;
    }

    &.no-padding {
      .card-body {
        padding: 0;
      }
    }

    &:not(:last-child) {
      margin-bottom: 0.75rem;
    }
  }

  .btn-link {
    padding: 0.75rem 1rem;
    display: flex;
    align-items: center;

    .arrow {
      width: 1.25rem !important;
      height: 1.25rem !important;
    }

    svg {
      transition: all 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955);
    }

    &.collapsed {
      &>svg {
        transform: rotateX(-180deg);
      }
    }
  }
}

.nav-status {
  .nav-item {
    &:not(:last-child) {
      margin-right: 0.75rem;
    }

    .nav-link {
      padding: 0.786rem 1rem;
      background-color: mix($white, $orange, 95%);
      border-color: mix($white, $orange, 50%);

      .feather {
        color: $orange;
        stroke-width: 3;
      }

      &.active {
        background-color: $orange;
        border-color: $orange;

        .feather {
          color: $white !important;
        }
      }

      &.finish {
        background-color: mix($white, $primary, 95%);
        border-color: mix($white, $primary, 50%);

        .feather {
          color: $primary;
        }

        &.active {
          background-color: $primary;
          border-color: $primary;
        }
      }
    }
  }
}

.conclusion-custom-radio {
  display: flex;

  .conclusion-btn {
    flex: 1;
    display: flex;
    align-items: center;
    padding: 1.15rem 1rem;
    border: 1px solid $border-color;
    border-radius: $border-radius-sm;
    cursor: pointer;
    font-size: 1.25rem;
    transition: 0.2s ease;

    &.success {
      border-color: mix($white, $success, 50%);

      &.checked {
        background-color: mix($white, $success, 90%);
      }
    }

    &.danger {
      border-color: mix($white, $danger, 50%);

      &.checked {
        background-color: mix($white, $danger, 90%);
      }
    }

    .custom-checkbox {
      padding-left: 1.5rem;
      height: 1.5rem;
    }
  }
}

.filter-box {
  padding: 0.5rem 1rem;
  background-color: $gray-50;

  .custom-label {
    margin-right: 5px;
    text-align: right;
    width: 60px;
  }

  @include media-breakpoint-up(sm) {
    padding: 0.5rem 1.5rem;
  }

  app-daterange-picker,
  .in-filter-box {
    width: 100%;
  }

  &.alingment {
    .row {
      .form-group-inline {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        label {
          white-space: nowrap;
        }
      }

      .group-w-btn>.row {
        flex-direction: column;
        align-items: flex-end;

        &>* {
          &:not(:last-child) {
            flex: 1;
          }
        }

        @include media-breakpoint-up(md) {
          flex-direction: row;
        }
      }

      @include media-breakpoint-up(xl) {
        .form-group-inline {
          flex-direction: row;
          align-items: center;
        }
      }

      .xs-6 {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0;

        @include media-breakpoint-up(md) {
          padding: 0 0.75rem;
          margin-left: -0.75rem;
          margin-right: -0.75rem;
        }

        &>* {
          padding: 0 0.75rem;
        }
      }
    }
  }
}

.daterange-wrapper {
  display: flex;
  width: 100%;

  @include media-breakpoint-up(md) {
    width: 320px;
  }

  @include media-breakpoint-up(xxl) {
    width: 360px;
  }

  .input-group {
    flex: 1;

    .form-control {
      width: 100% !important;
      padding-right: 0px;
      border-right: none;
    }

    .input-group-text {
      padding-left: 0px;
    }

    &:first-child {
      .input-group-text {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }

    &:last-child {
      .form-control {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
  }

  .addon {
    height: 100%;
    padding: 0.608rem 0.75rem;
    background-color: $custom-2;
  }
}

.datepicker-group {
  .form-control {
    padding-right: 0px;
    border-right: none;
  }

  .input-group-text {
    padding-left: 0px;

    &.disabled {
      background-color: $gray-100;
    }
  }
}

.notification-request {
  &-main {
    line-height: 1.2 !important;
    min-width: 22px !important;
    height: 22px !important;
  }

  &-sub {
    line-height: 1.2 !important;
    min-width: 22px !important;
    height: 22px !important;
  }
}

.mb-1-5 {
  margin-bottom: 1.5rem !important;
}

.screen-blinding {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f3f4f5;
  text-align: center;
  padding: 1.25rem;
}

body.blinding {
  overflow: hidden;

  iframe {
    display: none;
  }
}