// Angular Dialog
@use 'sass:math';

.cdk-overlay-container {
  z-index: 1050;
}

.cdk-overlay-dark-backdrop {
  background: rgba($black, 0.25);
  background-color: rgba($black, 0.25);
}

.mat-dialog-container {
  padding: 0 !important;
  box-shadow: $box-shadow !important;

  .modal-header {
    // background-color: $body-bg;
    background-color: $white;
    border-bottom: 1px solid $border-color;
    border-top-left-radius: $modal-content-border-radius;
    border-top-right-radius: $modal-content-border-radius;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    padding: 0.75rem 1rem;
    align-items: center;
    // close button

    @include media-breakpoint-up(sm) {
      padding: 0.75rem 1.5rem;
    }

    .modal-title {
      font-size: 1.145rem;
    }

    .btn-close {
      background-color: $white;
      border: none;
    }

    &.sticky {
      position: sticky;
      top: 0;
      z-index: 10;
    }
  }

  .modal-body {
    padding: 0;
    border-bottom-left-radius: $modal-content-border-radius;
    border-bottom-right-radius: $modal-content-border-radius;

    .modal-body-wrapper {
      padding: 1rem 1rem;

      @include media-breakpoint-up(sm) {
        padding: 1rem 1.5rem;
      }
    }

    &.bg-form {
      background-color: $body-bg;
    }

    &.fix-height {
      display: flex;
      flex-direction: column;
      overflow: auto;
      width: 100%;
      height: calc(95vh - 117px);

      .modal-body-wrapper {
        flex: 1;
      }
    }
  }

  .modal-footer {
    padding: 1rem 1rem;
    justify-content: flex-start;
    border-top: 1px solid $border-color;
    background-color: $white;

    @include media-breakpoint-up(sm) {
      padding: 1rem 1.5rem;
    }

    .btn {
      margin: 0;
    }

    &.sticky {
      position: sticky;
      bottom: 0px;
      z-index: 10;
    }
  }
}