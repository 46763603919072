// table-white-space
.table-white-space {

  th,
  td {
    white-space: nowrap;
  }
}

// table th, td padding
.table {

  thead,
  tfoot {
    th {
      vertical-align: top;
      text-transform: uppercase;
      font-size: $table-th-font-size;
      font-family: $font-family-th;
      letter-spacing: 0;
      border-width: 1px !important;
      border-top: 0px;
    }
  }

  th,
  td {
    padding: $table-cell-padding 2rem;
    vertical-align: middle;
  }

  td {
    border-top: 0px;
    border-bottom: 1px solid $border-color;
  }

  &.table-custom-light {
    th {
      background-color: $gray-200;
    }

    td {
      background-color: $white;
    }
  }

  &.table-sm {

    th,
    td {
      padding: $table-cell-padding-sm 0.5rem;

      &:first-child {
        padding-left: 0.75rem;
      }
    }
  }

  &.table-sm-space {

    th,
    td {
      padding: 0.75rem;
    }

    th {
      border-top: none;
      border-bottom: none;
    }

    td {
      border-top: none;
    }
  }

  &.table-xs-space {

    th,
    td {
      padding: 0.50rem;
    }
  }

  &.table-mobile {
    @include media-breakpoint-down(sm) {
      padding: 1rem;
      background-color: $light;
      display: block;

      thead {
        display: none;
      }

      tbody {
        display: flex;
        width: 100%;
        flex-direction: column;

        tr {
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-template-rows: auto 1fr;
          grid-column-gap: 0px;
          grid-row-gap: 0px;
          border-radius: 0.375rem;
          border: 1px solid $border-color;
          background-color: $white;

          &:not(:last-child) {
            margin-bottom: 0.75rem;
          }

          td {
            border-left: none;
            border-right: none;
            border-top: none;
            // border-bottom: none;
            border-radius: 0;
            background-color: transparent;
            text-align: left;
            padding: 0.50rem 0.75rem !important;

            &.cw-1 {
              grid-column: 1 / span 2;
              border-top: none;
            }

            &.bd-r {
              border-right: 1px solid $border-color;
            }

            &.bd-b {
              border-bottom: 1px solid $border-color !important;
            }

            &.no-bd-b {
              border-bottom: 0px !important;
            }

            &:last-child {
              border-bottom: 0;
            }

            .mobile-label {
              color: $text-muted;
              font-size: $font-size-sm;
            }

            .btn-icon {
              padding: 0.25rem;

              .feather {
                width: 20px;
                height: 20px;
              }

              &.h-17 {
                .feather {
                  height: 17px;
                }
              }
            }

            &.option {
              order: 99;
              border-bottom: 0;
            }
          }
        }
      }
    }
  }

  &.table-truncate {

    th:not(.wrap),
    td:not(.wrap) {
      white-space: nowrap;
    }
  }

  .cell-line-height {
    line-height: 1;
  }

  &.table-font-th-data {
    td {
      font-family: $font-family-th-data;

      .badge {
        font-family: $font-family-th;
      }
    }
  }

  &.table-align-top {
    td {
      vertical-align: top;
    }
  }

  &.table-form {
    th {
      background-color: mix($white, $primary, 90%) !important;
      // color: $white;
      text-align: center;
      vertical-align: middle;
      font-size: calc($font-size-base - 1px);
    }

    td {
      vertical-align: top;
      font-size: calc($font-size-base - 1px);

      &.result-cell {
        height: 1px;
        font-size: 1.15rem;
        font-weight: $font-weight-bold;

        &.not-pass {
          background-color: mix($white, $danger, 80%);
          color: $danger;
        }

        &.pass {
          background-color: mix($white, $primary, 80%);
          color: $primary;
        }
      }
    }
  }
}

// if we are not using table-light class then also header color should apply
.table:not(.table-dark):not(.table-light) {

  thead:not(.thead-dark) th,
  tfoot:not(.thead-dark) th {
    background-color: $table-head-bg;
  }
}

// table inside card, don't need margin bottom
.table-hover {
  tbody {
    tr {
      cursor: pointer;
    }
  }
}

// table border radius
.card .table {
  margin-bottom: 0;
  // border-bottom-left-radius: $border-radius;
  // border-bottom-right-radius: $border-radius;

  tbody {
    tr:last-child {
      td:first-child {
        border-bottom-left-radius: $border-radius;
      }

      td:last-child {
        border-bottom-right-radius: $border-radius;
      }
    }
  }
}

// Contexual table inside card radius
.card {
  table {
    tr[class*='table-']:last-child {
      td:first-child {
        border-bottom-left-radius: $border-radius;
      }

      td:last-child {
        border-bottom-right-radius: $border-radius;
      }
    }
  }
}