@import '../bootstrap-extended/include'; // Bootstrap includes
@import '../components/include'; // Components includes

$auth-1-inner-max-width: 400px !default;

.auth-wrapper {
  position: fixed;
  display: flex;
  flex-basis: 100%;
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100);
  width: 100%;

  .auth-inner {
    width: 100%;
    position: relative;
  }

  &.auth-v1 {
    align-items: center;
    justify-content: center;
    // overflow: hidden; // For v1 scroll for long auth form

    .auth-inner {
      &:before {
        width: 244px;
        height: 243px;
        content: ' ';
        position: absolute;
        top: -54px;
        left: -46px;
        background-image: url(../../../../assets/images/pages/cbimage-1.svg);
        opacity: 0.75;

        @include media-breakpoint-down(xs) {
          display: none;
        }
      }

      &:after {
        width: 272px;
        height: 272px;
        content: ' ';
        position: absolute;
        bottom: -55px;
        right: -75px;
        background-image: url(../../../../assets/images/pages/cbimage-2.svg);
        z-index: -1;
        opacity: 0.75;

        @include media-breakpoint-down(xs) {
          display: none;
        }
      }
    }
  }

  &.auth-v1 .auth-inner {
    max-width: $auth-1-inner-max-width;
  }

  .brand-logo {
    display: flex;
    justify-content: center;
    margin: 1rem 0 2rem 0;

    .brand-text {
      font-weight: 600;
    }
  }

  .auth-footer-btn {
    .btn {
      padding: 0.6rem !important;

      &:not(:last-child) {
        margin-right: 1rem;
      }
    }
  }
}

@media (min-width: 1200px) {
  .auth-wrapper {
    &.auth-v2 .auth-card {
      width: $auth-1-inner-max-width;
    }
  }
}

.auth-wrapper .auth-bg {
  background-color: $white;
}

.dark-layout {
  .auth-wrapper .auth-bg {
    background-color: $theme-dark-card-bg;
  }
}

@media (max-height: 625px) {
  .dark-layout {
    .auth-wrapper .auth-inner {
      background-color: $theme-dark-card-bg;
    }
  }

  .auth-wrapper {
    .auth-bg {
      padding-top: 3rem;
    }

    .auth-inner {
      background-color: $white;
      padding-bottom: 1rem;
    }

    &.auth-v2 .brand-logo {
      width: 100%;
      display: flex;
      justify-content: unset;
      position: relative;
      left: 0;
      padding-left: 1.5rem;
    }
  }
}